import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { NavigationType, SiteType } from '~/utilities/graphql/codegen';
import { media } from '../../core/styles';
import { useStaticContext } from '~/utilities/context/static/StaticContext';
import { useNavActionContext } from '~/utilities/context/dynamic/NavActionContext';
import { useAppContext } from '~/utilities/context/static/AppContext';

const style = {
  GStarStick: css<{ $opened: boolean }>`
    ${({ theme, $opened }) => css`
      @media ${media(theme).greaterThan('sm')} {
        height: 2px;
        margin: 4px 0;

        &:first-child {
          ${$opened && `transform: translate(0, 6px) rotate(45deg);`}
        }

        &:last-child {
          ${$opened && `transform: translate(0, -6px) rotate(-45deg);`}
        }
      }

      @media ${media(theme).greaterThan('xl')} {
        &:first-child,
        &:last-child {
          transform: none;
        }

        &:nth-child(2) {
          opacity: 1;
        }
      }
    `}
  `,

  AafStick: css<{ $opened: boolean }>`
    ${({ theme, $opened }) => css`
      @media ${media(theme).greaterThan('sm')} {
        height: 2px;
        margin: 5px 0;

        &:first-child {
          ${$opened && `transform: translate(0, 7px) rotate(45deg);`}
        }

        &:last-child {
          ${$opened && `transform: translate(0, -7px) rotate(-45deg);`}
        }
      }
    `}
  `,
};

const Stick: Record<SiteType, RuleSet<{ $opened: boolean }>> = {
  [SiteType.Gstar]: style.GStarStick,
  [SiteType.Outlet]: style.GStarStick,
  [SiteType.Employeeshop]: style.GStarStick,
  [SiteType.Aaf]: style.AafStick,
};

const S = {
  HamburgerButton: styled.button<{ $isFlyout: boolean }>`
    ${({ theme, $isFlyout }) => css`
      border: 0;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 0;
      height: var(--top-nav-height);
      width: ${theme.siteType === SiteType.Gstar ? '56px' : '54px'};
      display: inline-block;
      position: relative;

      @media ${media(theme).greaterThan('lg')} {
        display: ${$isFlyout ? 'none' : 'inline-block'};
      }
    `}
  `,
  HamburgerIcon: styled.span`
    ${({ theme }) => css`
      position: absolute;
      display: inline-block;
      height: 14px;
      left: calc(50% - 9px);
      top: calc(50% - 7px);
      width: 18px;

      @media ${media(theme).greaterThan('sm')} {
        height: ${theme.siteType === SiteType.Gstar ? '14px' : '18px'};
        width: ${theme.siteType === SiteType.Gstar ? '18px' : '24px'};
        left: ${theme.siteType === SiteType.Gstar ? 'calc(50% - 9px)' : 'calc(50% - 12px)'};
        top: calc(50% - 9.5px);

        ${theme.siteType === SiteType.Aaf &&
        css`
          top: calc(50% - 5px);
        `};

        ${theme.siteType === SiteType.Gstar &&
        css`
          top: calc(50% - 7px);
        `};
      }
    `}
  `,
  Stick: styled.span<{ $opened: boolean; $isGStar: boolean }>`
    background-color: ${({ theme }) => theme.colors.content.component.header.color};
    display: block;
    height: 2px;
    margin: ${({ theme }) => `${theme.padding['3xs']} ${theme.padding.none}`};
    transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1) 0.3s;
    width: 100%;
    opacity: 1;

    &:first-child {
      margin-top: 0;
      ${({ $opened }) => $opened && `transform: translate(0, 6px) rotate(45deg);`}
    }

    &:nth-child(2) {
      ${({ $opened }) => $opened && `opacity: 0;`}
    }

    &:last-child {
      margin-bottom: 0;
      ${({ $opened }) => $opened && `transform: translate(0, -6px) rotate(-45deg);`}
    }

    ${({ theme }) => Stick[theme.siteType]}

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

export const HamburgerButton = (): ReactElement => {
  const { sideNavOpened, toggleSideNav } = useNavActionContext();
  const {
    configuration: { navigationType },
    accessibilityLabels: { openSideBar, closenSideBar } = {},
  } = useStaticContext();
  const { isGStar } = useAppContext();

  return (
    <S.HamburgerButton
      $isFlyout={navigationType === NavigationType.HorizontalMainNavigationFlyOut}
      suppressHydrationWarning
      onClick={toggleSideNav}
      data-testid="hamburger-menu"
      aria-label={sideNavOpened ? closenSideBar : openSideBar}
      aria-controls="sideNav"
      id="hamburger-menu"
    >
      <S.HamburgerIcon>
        <S.Stick $isGStar={isGStar} suppressHydrationWarning $opened={sideNavOpened} />
        <S.Stick $isGStar={isGStar} suppressHydrationWarning $opened={sideNavOpened} />
        <S.Stick $isGStar={isGStar} suppressHydrationWarning $opened={sideNavOpened} />
      </S.HamburgerIcon>
    </S.HamburgerButton>
  );
};
