import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const SearchIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg width="16" height="16" $hoverColor={hoverColor}>
    <g fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 7A5.4 5.4 0 1 1 1.7 7a5.4 5.4 0 0 1 10.8 0Zm-1 5.6a7 7 0 1 1 1.2-1.2l3 3.2a.8.8 0 0 1-1.1 1.2l-3.1-3.2Z"
      />
    </g>
  </Svg>
);

export default SearchIcon;
